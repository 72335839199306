import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { siteMetadata } from "../../gatsby-config";
import PinterestGridGallery from "../components/PinterestGridGallery";

import Layout from "../components/layout";
import { section, section__content, title } from "./page.module.scss";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <div className="container-fluid" style={{ padding: 0 }}>
          <div className="row">
            <motion.div
              className={title}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 1,
                },
              }}
            >
              <h1>About</h1>
            </motion.div>
          </div>
          <div className="row">
            <div className="col-12">
              <section className={section}>
                <div className="col-6 featured-image">

                <div>
                  <PinterestGridGallery />
                </div>
                </div>
                <motion.div
                  className={section__content}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 1,
                    },
                  }}
                  style={{ paddingBottom: 30 }}
                >
                  <p>
                  I'm Lily, a Danish UI/UX Designer & UX Engineer with 9+ years of experience in UI/UX Design and Development.
                  </p>
                  <p>
                  I'm passionate about solving problems with design systems and by building user-centered experiences. Notably, I led a collaborative effort within a design team of three to spearhead the creation of a new design system and B2C/B2B platform at Columbia University, New York, aimed at modernizing and consolidating 100+ department websites.
                  </p>
                  <p>
                  My journey in this field started in the '90s where I created my first website with GeoCities. I discovered UX design in a class at the University of Copenhagen. And in 2013, I moved to New York to study digital design and web development at The New School Media Studies and Parsons School of Design. Since then, I've developed my skills by collaborating with in-house product development teams on an international scale and providing consultancy services.
                  </p>
                  <p>
                  When I’m not working, I enjoy traveling to visit friends and family around the world and exploring big cities. I also love live music, listening to podcasts, and making crafts. 
                  </p>
                </motion.div>
              </section>
            </div>
          </div>

        </div>
      </Layout>
    </>
  );
};

export default AboutPage;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { galleryGrid, galleryGridItem } from "./PinterestGridGallery.module.scss";

const PinterestGridGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "about-gallery" }
          extension: { regex: "/(jpg)|(jpeg)|(png)|(gif)/" }
        }
        limit: 4
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges;

  return (
    <div className={galleryGrid}>
      {images.map(({ node }) => {
        const image = getImage(node);
        return (
          <div key={node.id} className={galleryGridItem}>
            <GatsbyImage image={image} alt="" />
          </div>
        );
      })}
    </div>
  );
};

export default PinterestGridGallery;
